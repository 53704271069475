
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import CommonProgress from "@/components/common/Progress.vue";
import SpouceMain from "@/components/spouce/Main.vue";

@Component({
  components: {
    CommonProgress,
    SpouceMain,
  }
})
export default class EmployeeEach extends Mixins(mixinEmployeeEach) {
}
