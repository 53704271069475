
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';

@Component({
  components: {
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  get old_spouce() {
    if (this.family.spouce !== null && this.family.spouce.id == this.tmp_spouce.spouce_id) {
      return this.family.spouce;
    } else {
      return {};
    }
  }
  get tmp_spouce() {
    if (this.is_demand_admit_page) {
      var ch_spouce = this.ch_spouce as any;
      return ch_spouce;
    } else if (this.is_special_page) {
      return this.m.spouce;
    } else {
      return this.spouce;
    }
  }
}
