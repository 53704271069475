
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import global from "@/vuex/company/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/company/manager";
import header from "@/vuex/header";
import CommonText from "@/components/common/Text.vue";
import CommonDate from "@/components/common/Date.vue";
import CommonRadio from "@/components/common/Radio.vue";

@Component({
  components: {
    CommonText,
    CommonDate,
    CommonRadio,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  get tmp_spouce() {
    return this.m.spouce;
  }

  set tmp_spouce(val) {
    this.m.setSpouce({spouce: val});
  }
}
