
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import CommonProgress from "@/components/common/Progress.vue";
import CommonButton from "@/components/common/Button.vue";
import SpouceInputBaseinfo from "@/components/spouce/input/Baseinfo.vue";
import SpouceDisplayBaseinfo from "@/components/spouce/display/Baseinfo.vue";
import SpouceInputSelfinfo from "@/components/spouce/input/Selfinfo.vue";
import SpouceDisplaySelfinfo from "@/components/spouce/display/Selfinfo.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";

@Component({
  components: {
    CommonProgress,
    CommonButton,
    SpouceInputBaseinfo,
    SpouceDisplayBaseinfo,
    SpouceInputSelfinfo,
    SpouceDisplaySelfinfo,
    CommonBigbutton,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  created() {
    if (this.is_add) {
      this.m.setSpouce({spouce: {
        employee_id: this.employee_id, 
        kana: null, 
        name: null, 
        birth_date: null,
        mynum: null, 
        is_housemate: 1, 
        disability_type: 0, 
        income: 0,
        retire_income: 0, 
        other_income: 0, 
        is_not_live: false, 
        address: null, 
        not_live_money: null,
        is_special_disability_housemate: 1,
        disability_reason: null,
      }});
    }
  }

  edit_spouce() {
    this.tmp_spouce = util.deep_copy(this.spouce);
    this.m.editSpouce();
  }
  update_spouce() {
    this.m.updateSpouce({employee_id: this.employee_id, spouce_id: this.spouce_id});
  }
  create_spouce() {
    this.m.createSpouce({employee_id: this.employee_id});
  }

  get tmp_spouce() {
    return this.m.spouce;
  }

  set tmp_spouce(val) {
    this.m.setSpouce({spouce: val});
  }

  get is_add() {
    return this.spouce_id == 0;
  }
}
