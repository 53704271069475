
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinEmployeeEach from '@/mixins/mixinEmployeeEach';
import Earning from "@/entities/Earning";
import CommonInfobutton from "@/components/common/Infobutton.vue";

@Component({
  components: {
    CommonInfobutton,
  }
})
export default class Main extends Mixins(mixinEmployeeEach) {
  get income_sum() {
    const earning = new Earning();
    return earning.income2earning(Number(this.tmp_spouce.income)) + Number(this.tmp_spouce.other_income);
  }
  get old_income_sum() {
    if (this.old_spouce.id) {
      const earning = new Earning();
      return earning.income2earning(Number(this.old_spouce.income)) + Number(this.old_spouce.other_income);
    } else {
      return null;
    }
  }
  get old_spouce() {
    if (this.family.spouce !== null && this.family.spouce.id == this.tmp_spouce.spouce_id) {
      return this.family.spouce;
    } else {
      return {};
    }
  }
  get tmp_spouce() {
    if (this.is_demand_admit_page) {
      var ch_spouce = this.ch_spouce as any;
      return ch_spouce;
    } else if (this.is_special_page) {
      return this.m.spouce;
    } else {
      return this.spouce;
    }
  }
}
